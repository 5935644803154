<template>
  <page-layout title="Pay with fawry">
    <div class="px-2 mt-16">
      <h1 class="text-xl md:text-3xl text-center px-6">
        One last step to confirm your session!
      </h1>
      <ol class="flex flex-col space-y-6 list-decimal pt-16 px-4">
        <li class="font-semibold">
          <p class="text-base md:text-xl">
            Visit any Fawry outlet or use myFawry app
          </p>
        </li>

        <li class="font-semibold">
          <p class="text-base md:text-xl">
            Transfer
            <span class="text-orange font-bold"
              >{{ upcoming_payment }} EGP</span
            >
            to Tutoruu’s Orange Cash account number:
            <span class="text-orange font-bold">01200525233</span>
          </p>
        </li>
        <li class="font-semibold">
          <p class="text-base md:text-xl">
            Send us an image of the receipt through
            <a
              href="https://wa.me/message/O5347M4QVDTBF1"
              target="_blank"
              class="text-orange font-bold"
              >WhatsApp</a
            >
            or
            <a
              href="mailto:support@tutoruu.com?subject=Payment%20Receipt"
              target="_blank"
              class="text-orange font-bold"
              >Email</a
            >
            to confirm your session.
          </p>
        </li>
      </ol>
    </div>
  </page-layout>
</template>

<script>
import pageLayout from "../components/base/pageLayout.vue";
export default {
  components: {
    pageLayout,
  },
  computed: {
    upcoming_payment() { return window.localStorage.getItem("upcoming_payment") }
  }
};
</script>